import React from 'react';
import {ProductsSection,ProductsInner,Heading,SubHeading,
    ProductsCol2,ProductsCol1,ProductsBtn} from './About.style';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";


const Products  = () => {
    const JSONData  = useStaticQuery(graphql`{
  appJson {
    Products {
      DelayConstant
      ProductsRightImg {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      ProductsCol2 {
        ProductsHeading
        ProductsSubHeading
        ProductsPara
        ProductsBtn
      }
    }
  }
}
`);
    return (
        <ProductsSection id="productContainer">
                <ProductsInner>
                    <ProductsCol1>
                        <Fade bottom delay={1*JSONData.appJson.Products.DelayConstant}>
                            <GatsbyImage
                                image={JSONData.appJson.Products.ProductsRightImg.childImageSharp.gatsbyImageData}
                                className="ProductsRightImg"
                                imgStyle={{
                                    marginBottom:"0px",
                                }}
                                alt="" />
                        </Fade>
                    </ProductsCol1>
                    <ProductsCol2>
                        <Fade bottom delay={1*JSONData.appJson.Products.DelayConstant}>
                            <Heading>
                                {JSONData.appJson.Products.ProductsCol2.ProductsHeading}
                            </Heading>
                            <SubHeading>
                                {JSONData.appJson.Products.ProductsCol2.ProductsSubHeading}
                            </SubHeading>
                            <p>
                                {JSONData.appJson.Products.ProductsCol2.ProductsPara}
                            </p>
                            <ProductsBtn href="#">
                                {JSONData.appJson.Products.ProductsCol2.ProductsBtn}
                            </ProductsBtn>
                        </Fade>
                    </ProductsCol2>
                </ProductsInner>
        </ProductsSection>
    );
}
export default Products;
