import React from 'react';
import {FeaturesSection,FeaturesContents,FeaturesCard,FeaturesInner,CustomContainer,
FeaturesCardImg,FeaturesRow,FeaturesBtn,ContainerWrapper
} from './featurecards.style';
import {Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                appJson{
                    Features{
                        DelayConstant
                        FeaturesContents{
                            FeaturesCardIcon
                            FeaturesCardHeading
                            FeaturesCardPara
                            FeaturesBtn
                        }
                    }
                }
            }
      `);
    return (
        <FeaturesSection>
            <FeaturesInner>
            <ContainerWrapper>
                <CustomContainer>
                    <FeaturesRow>
                        <Col md="12" lg="12">
                            <FeaturesContents>
                                <Fade bottom delay={1*JSONData.appJson.Features.DelayConstant}>
                                    <Row>
                                        {
                                            JSONData.appJson.Features.FeaturesContents.map(item => {
                                                return <Col lg="4" md="4" sm="12">
                                                    <FeaturesCard>
                                                        <FeaturesCardImg>
                                                            <img src={item.FeaturesCardIcon} alt=""/>
                                                        </FeaturesCardImg>
                                                        <h4>{item.FeaturesCardHeading}</h4>
                                                        <p>{item.FeaturesCardPara}</p>
                                                        <FeaturesBtn href="#">{item.FeaturesBtn}</FeaturesBtn>
                                                    </FeaturesCard>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </Fade>
                            </FeaturesContents>
                        </Col>
                    </FeaturesRow>
                </CustomContainer>
                </ContainerWrapper>
            </FeaturesInner>
        </FeaturesSection>

    );
}

export default Features;
