import {createGlobalStyle} from 'styled-components';
import {device} from './device';


const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Lato', sans-serif;
        font-weight: 400;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        color:#262626;
    }
    h1{
        font-size:42px;
        line-height:52px;
        margin-bottom:20px;

        @media ${device.laptop} {
            font-size:36px;
            line-height:42px;
        }
        @media ${device.mobileL} {
            font-size:32px;
            line-height:38px;
        }
    }
    h2{
        font-size: 36px;
        margin-bottom: 20px;
        line-height:42px;
    }
    h3{
        font-size: 26px;
        margin-bottom: 20px;
        line-height:32px;
    }
    h4{
        font-size: 23px;
        margin-bottom: 20px;
        line-height:29px;

        @media ${device.laptop} {
            font-size:20px;
            line-height:26px;
        }
        @media ${device.tablet} {
            font-size:22px;
            line-height:28px;
        }
    }
    h5{
        font-size: 20px;
        margin-bottom: 20px;
        line-height:26px;
    }
    h6{
        font-size: 18px;
        margin-bottom: 20px;
        line-height:24px;
    }
    p{
        font-size:14px;
        margin-bottom: 20px;
        line-height:1.7;
        color:#525252;
    }
    a{
        text-decoration:none;
        font-size:14px;
    }
    img{
        margin-bottom:0px;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
