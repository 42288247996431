import styled from 'styled-components';

export const Commonbtn = styled.a`
    font-size:14px;
    background: #5ce59b;
    color: #fff;
    padding: 9px 40px;
    text-decoration:none;
    font-weight:bold;
    border-radius: 25px;
    display: inline-block;
    box-shadow: 0px 0px 13px -2px #5ce59b;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

    :hover{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        text-decoration:none;
        color:#fff;
    }
`;

export const SectionHeading = styled.h2`
    margin-bottom:15px;
    text-align:center;
    line-height:1;
`;

export const SectionSubHeading = styled.h5`
    text-align:center;
    font-weight:500;
    line-height:1;
    margin-bottom:0px;
`;
