import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonbtn,SectionSubHeading,SectionHeading} from '../Common/common.style';

export const ProductsSection = styled.section`
    padding:100px 0px;
    position:relative;

    @media ${device.tablet} {
        padding: 80px 0px;
    }
`;

export const ProductsInner = styled.div`
    display:flex;
    align-items:center;

    @media ${device.minlaptopL} {
        max-width:1140px;
        margin:0px auto;
    }

    @media ${device.tablet} {
        display:block;
    }
`;

export const ProductsCol1 = styled.div`
    width:60%;
    @media ${device.tablet} {
        width:100%;
    }

    .ProductsRightImg{
        text-align:left;
    }
`;

export const ProductsCol2 = styled.div`
    width:40%;
    text-align:left;
    padding-left:30px;

    @media ${device.laptopL} {
        margin-right: calc(50% - 570px);
    }

    @media ${device.laptopM} {
        margin-right: calc(50% - 480px);
    }

    @media ${device.laptop} {
        margin-right: calc(50% - 360px);
    }

    @media ${device.tablet} {
        width:100%;
        max-width: 540px;
        margin:auto;
        padding:30px 25px 0px;
    }


`;




export const ProductsBtn = styled(Commonbtn)`

`;
export const SubHeading = styled(SectionSubHeading)`
    font-weight:500;
    text-align:left;
    margin-bottom:20px;
`;
export const Heading = styled(SectionHeading)`
    margin-bottom:15px;
    text-align:left;
`;
