import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {DownloadSection,DownloadInner,DownloadCol1,DownloadContentBg,
    DownloadLinkWrapper,DownloadLeftCol,DownloadCol2,SubHeading,Heading
} from './download.style';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Download = () =>{
    const JSONData  = useStaticQuery(graphql`{
  appJson {
    Download {
      DelayConstant
      DownloadTitle
      DownloadSubTitle
      DownloadPara
      DownloadLinkImgApple {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      DownloadLinkImgGoogle {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      DownloadDemo {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
    return (
        <DownloadSection id="downloadContainer">
            <DownloadContentBg>
                <Container>
                <Row>
                    <Col md="12">
                        <DownloadInner>

                            <DownloadCol1>
                                <Fade left delay={1*JSONData.appJson.Download.DelayConstant}>
                                        <Heading>
                                            {JSONData.appJson.Download.DownloadTitle}
                                        </Heading>
                                    </Fade>
                                    <Fade left delay={2*JSONData.appJson.Download.DelayConstant}>
                                        <SubHeading>
                                        {JSONData.appJson.Download.DownloadSubTitle}
                                        </SubHeading>
                                    </Fade>
                                    <Fade left delay={3*JSONData.appJson.Download.DelayConstant}>
                                        <p>
                                        {JSONData.appJson.Download.DownloadPara}
                                        </p>
                                    </Fade>

                                <Fade left delay={1*JSONData.appJson.Download.DelayConstant}>
                                    <DownloadLinkWrapper>
                                        <DownloadLeftCol>
                                            <GatsbyImage
                                                image={JSONData.appJson.Download.DownloadLinkImgApple.childImageSharp.gatsbyImageData}
                                                imgStyle={{
                                                    marginBottom:"0px",
                                                }}
                                                className="DownloadImage"
                                                alt="" />
                                        </DownloadLeftCol>
                                        <DownloadLeftCol>
                                            <GatsbyImage
                                                image={JSONData.appJson.Download.DownloadLinkImgGoogle.childImageSharp.gatsbyImageData}
                                                className="DownloadImage"
                                                imgStyle={{
                                                    marginBottom:"0px",
                                                }}
                                                alt="" />
                                        </DownloadLeftCol>
                                    </DownloadLinkWrapper>
                                </Fade>
                            </DownloadCol1>

                            <DownloadCol2>
                                <Fade right delay={1*JSONData.appJson.Download.DelayConstant}>
                                    <GatsbyImage
                                        image={JSONData.appJson.Download.DownloadDemo.childImageSharp.gatsbyImageData}
                                        className="DownloadDemo"
                                        imgStyle={{
                                            margin:"0px auto",
                                            width:"auto",
                                            right:"0px"
                                        }}
                                        alt="" />
                                </Fade>
                            </DownloadCol2>
                        </DownloadInner>
                    </Col>
                </Row>
            </Container>
            </DownloadContentBg>
        </DownloadSection>
    );
}
export default Download;
